<template>
  <div>
    <v-row dense class="text-center justify-center">
      <v-col cols="12">
        <v-card-title v-if="item && item.id > 0">
          <v-btn icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <span>{{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}</span>
          <v-avatar class="ml-2" tile>
            <v-img :src="item.banner_square"> </v-img>
          </v-avatar>
        </v-card-title>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row dense class="mt-4 text-center justify-center">
      <v-col v-for="item in children" :key="item.id" cols="6" md="4">
        <v-card
          @click="$router.push({ name: 'exam', params: { id: item.id } })"
          elevation="0"
          style="overflow: hidden"
          height="150"
          class="d-flex flex-column justify-space-around rounded-lg"
        >
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <v-avatar size="70" class="justify-self-cent">
              <v-img
                :src="
                  item.banner_square
                    ? item.banner_square
                    : 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&h=400&q=80'
                "
              />
            </v-avatar>
          </v-card-text>
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <div
              :class="'subtitle-1 ' + (isDark ? 'white--text' : 'black--text')"
            >
              {{
                isHindi
                  ? item.title_hi
                    ? item.title_hi
                    : item.title
                  : item.title
              }}
            </div>
            <div class="caption">
              <span class="mr-1" v-for="child in item.children" :key="child.id">
                {{
                  isHindi
                    ? child.title_hi
                      ? child.title_hi
                      : child.title
                    : child.title
                }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider
      v-if="items && items.length > 0 && children && children.length > 0"
      class="mt-5"
    ></v-divider>
    <v-row dense class="text-center justify-center">
      <v-col cols="12" v-if="item && item.id && (!items || items.length > 0)">
        <v-card-title class="mb-0 pb-0 justify-center">
          {{ $lang("AVAILABLETESTS") }}
        </v-card-title>
        <v-card-text class="mt-0 pt-0 text-center">
          {{ $lang("CHOOSETESTFROMBELOW") }}
        </v-card-text>
      </v-col>
      <v-col cols="12" md="6">
        <v-expansion-panels>
          <scroller
            style="width: 100%"
            :canLoadMore="!canLoadMore"
            :items="items"
            @reachedEnd="reachedEnd"
            @remove="(itm) => handleRemove(itm)"
            @removed="(itm) => handleRemove(itm)"
            @clicked="handleClick"
          >
            <template v-slot:default="{ item }">
              <mocktest-card :mocktest="item" />
            </template>
          </scroller>
        </v-expansion-panels>
        <v-progress-circular v-if="loading" indeterminate />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
// import PurchaseDialog from "./PurchaseDialog";

import MocktestCard from "./MocktestCard.vue";
import scroller from "./scroller.vue";

export default {
  name: "exam",
  components: {
    MocktestCard,
    // PurchaseDialog,
    scroller,
  },
  data() {
    return {
      loading: false,
      children: null,
      item: {},
      items: [],
      pagination: { page: 1 },
      benched: 0,
      page: 1,
      lastPage: 1,
      canLoadMore: true,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
      mocktests: "app/exams/mocktests",
      attemptedMocktests: "app/exams/attemptedMocktests",
    }),
    isDark() {
      return this.$store.getters.isThemeDark;
    },
  },
  methods: {
    handleClick(item) {},
    handleRemove(item) {},
    reachedEnd() {
      this.loadMoreFromApi();
    },
    loadMoreFromApi() {
      if (this.page > this.lastPage) {
        return;
      }
      return this.fetchItems(this.page)
        .then((data) => {
          this.lastPage = data.lastPage;
          this.items.push(...data.data);
          this.page++;
        })
        .catch((err) => {
          this.canLoadMore = false;
        });
    },
    fetchItems(page = 1) {
      this.loading = true;
      return axios
        .get(
          `prep/exams/public/${this.$route.params.id}/mocktests?orderby=featured|desc&perPage=10&page=${page}`
        )
        .then((res) => {
          res.data.data = res.data.data.map((el) => {
            let mts = this.mocktests.find(
              (mt) => mt.id == el.id && mt.elapsed > 0
            );

            if (mts) {
              el.elapsed = mts.elapsed;
            }

            //add attempted data to mocktest if attempted
            el.attempt = this.attemptedMocktests.find(
              (atmpt) => atmpt.prep_mocktest_id == el.id
            );

            return el;
          });
          this.canLoadMore = true;
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          this.canLoadMore = false;
          return Promise.reject(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.$store
      .dispatch("app/exams/ExamsGetOne", {
        context: this,
        id: this.$route.params.id,
      })
      .then(() => {
        document.title = `${this.item.title} - | vikarn.com`;
        return axios
          .get(`prep/exams/public/where/parent_id/${this.$route.params.id}`)
          .then((res) => {
            this.children = res.data.data;
          })
          .catch((err) => {
            console.log("children error", err);
          });
      })
      .then(() => {
        // this.fetchItems(this.page)
        //   .then((data) => {
        //     setTimeout(() => {
        //       this.items = data.data;
        //       document.title = `${this.item.title} - | vikarn.com`;
        //     }, 500);
        //   })
        //   .catch((err) => {});
      })
      .catch((err) => {
        this.$route.go(-1);
      });
  },
  mounted() {},
};
</script>
<style scoped>
</style>

