<template>
  <v-expansion-panel class="mb-3" v-if="mocktest">
    <v-expansion-panel-header expand-icon="">
      <div>
        <div class="subtitle-1 mb-1">
          <!-- <v-badge
            v-if="!mocktest.owned"
            top
            rounded
            :color="mocktest.vcoin ? 'orange darken-2' : 'grey darken-1'"
            :content="mocktest.vcoin < 1 ? 'free' : '⬤ ' + mocktest.vcoin"
            offset-x="23"
            offset-y="-14"
          ></v-badge>
          <v-badge
            v-if="mocktest.owned"
            top
            :color="'green darken-2'"
            :content="'✔️ owned'"
            offset-x="30"
            offset-y="-20"
          ></v-badge> -->
          <v-badge
            v-if="mocktest.elapsed"
            bordered
            bottom
            color="orange accent-4"
            dot
            offset-x="10"
            offset-y="10"
          ></v-badge>
          {{
            isHindi
              ? mocktest.title_hi
                ? mocktest.title_hi
                : mocktest.title
              : mocktest.title
          }}
        </div>
        <div class="caption grey--text d-flex flex-row">
          <span v-if="mocktest.vcoin > 0">
            <span v-if="!mocktest.owned">
              <v-avatar size="16" contain>
                <v-img src="/img/coins/coin-single-32.png" />
              </v-avatar>
              <span class="ml-1"
                ><strong>{{ mocktest.vcoin }}</strong></span
              >
            </span>
            <v-icon
              small
              class="mb-1"
              v-else
              :color="mocktest.attempt ? `blue` : `green`"
            >
              {{
                mocktest.attempt ? `mdi-check-circle` : `mdi-lock-open-variant`
              }}
            </v-icon>
          </span>
          <span v-else>
            <v-icon v-if="mocktest.attempt" small color="blue">
              mdi-check-circle
            </v-icon>
            <v-chip x-small v-else :class="'px-2 grey darken-1 white--text'">
              free
            </v-chip>
          </span>
          <!--
          <v-btn
            class="ml-2 grey--text"
            style="margin-left: -0.4rem"
            x-small
            text
          >
            <v-icon left>mdi-calendar</v-icon>
            {{ mocktest.updated_at | formatDateOnly }}
          </v-btn>
          -->
          <v-btn class="grey--text" x-small text>
            <v-icon left>mdi-clock</v-icon>
            {{ calculateDuration(mocktest) }}
          </v-btn>

          <v-btn
            v-if="false"
            class="grey--text"
            style="margin-left: -0.4rem"
            x-small
            text
            @click.stop="
              $router.push({
                name: 'profile',
                params: { id: mocktest.user.username },
              })
            "
          >
            <v-icon left>mdi-account-circle</v-icon>
            {{ mocktest.user.display_name }}
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      {{
        mocktest.segments.reduce(
          (prev, curr) => prev + curr.__meta__.questions_count,
          0
        )
      }}

      {{ $lang("qstn") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list dense style="margin: 0 -1.5rem">
        <v-list-item v-for="seg in mocktest.segments" :key="seg.id">
          {{ isHindi ? (seg.title_hi ? seg.title_hi : seg.title) : seg.title }}
          <v-spacer></v-spacer>
          {{ seg.__meta__.questions_count }}
          {{ $lang("qstn") }}
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item small>
          <v-icon left>mdi-account-circle</v-icon>
          {{ $lang("Created_By") }}
          <v-spacer></v-spacer>
          <span
            style="margin-left: -0.4rem"
            text
            @click.stop="
              $router.push({
                name: 'profile',
                params: { id: mocktest.user_id },
              })
            "
          >
            {{ mocktest.user ? mocktest.user.display_name : mocktest.user_id }}
          </span>
        </v-list-item>
      </v-list>
      <v-card-actions
        style="margin: 0 -1.5rem"
        class="text-center justify-center"
      >
        <purchase-dialog
          :mocktest="mocktest"
          v-if="mocktest.vcoin > 0 && !mocktest.owned"
        />
        <v-btn
          v-if="mocktest.vcoin < 1 || mocktest.owned"
          @click="
            $router.push({
              name: 'exams-mocktest-preview',
              params: { id: mocktest.id },
            })
          "
          :class="
            mocktest.attempt
              ? `orange darken-3`
              : mocktest.elapsed
              ? 'orange darken-3'
              : 'blue darken-3'
          "
          text
          block
          dark
        >
          {{
            $lang(
              mocktest.attempt
                ? "Result"
                : mocktest.elapsed
                ? "Resume"
                : "Start"
            )
          }}
          <span v-if="mocktest.elapsed">
            ({{ mocktest.elapsed.toString().fancyTime() }})
          </span>
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PurchaseDialog from "./PurchaseDialog";

export default {
  props: ["mocktest"],
  components: { PurchaseDialog },
  methods: {
    calculateDuration(mocktest) {
      if (!mocktest) {
        return "0:00";
      }
      let duration = 0;
      if (mocktest.duration > 0) {
        duration = mocktest.duration;
      } else {
        duration = mocktest.segments.reduce((pre, cur) => {
          return pre + cur.duration;
        }, 0);
      }
      return duration.toString().fancyTime();
    },
    // countQuestions(items) {
    //   if (!items) {
    //     return 0;
    //   }
    //   let total = 0;
    //   items.forEach((item) => {
    //     total += item.segments.reduce(
    //       (prev, curr) => prev + curr.__meta__.questions_count,
    //       0
    //     );
    //   });
    //   return total;
    // },
  },
};
</script>

<style>
</style>