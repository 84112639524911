<template>
  <DynamicScroller
    :items="items"
    :min-item-size="54"
    class="scroller px-0 mx-0"
  >
    <template #after @visible="handleAfterVisible">
      <v-card-title class="justify-center">
        <div v-intersect="endIntersect" />
      </v-card-title>
    </template>
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :size-dependencies="[item.title]"
        :data-index="index"
        @visible="log('item visible')"
      >
        <slot v-bind:item="item"></slot>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    items: Array,
    canLoadMore: Boolean,
  },
  components: {},
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    handleAfterVisible() {},
    handleClick(item) {
      this.$emit("clicked", item);
    },

    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        // console.log("reached-end");
        this.$emit("reachedEnd");
      }
    },
  },
};
</script>

<style scoped>
.scroller {
  height: 100%;
}

.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
.my-list {
  height: calc(100vh - 8rem);
}
</style>
