var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mocktest)?_c('v-expansion-panel',{staticClass:"mb-3"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""}},[_c('div',[_c('div',{staticClass:"subtitle-1 mb-1"},[(_vm.mocktest.elapsed)?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"orange accent-4","dot":"","offset-x":"10","offset-y":"10"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isHindi ? _vm.mocktest.title_hi ? _vm.mocktest.title_hi : _vm.mocktest.title : _vm.mocktest.title)+" ")],1),_c('div',{staticClass:"caption grey--text d-flex flex-row"},[(_vm.mocktest.vcoin > 0)?_c('span',[(!_vm.mocktest.owned)?_c('span',[_c('v-avatar',{attrs:{"size":"16","contain":""}},[_c('v-img',{attrs:{"src":"/img/coins/coin-single-32.png"}})],1),_c('span',{staticClass:"ml-1"},[_c('strong',[_vm._v(_vm._s(_vm.mocktest.vcoin))])])],1):_c('v-icon',{staticClass:"mb-1",attrs:{"small":"","color":_vm.mocktest.attempt ? "blue" : "green"}},[_vm._v(" "+_vm._s(_vm.mocktest.attempt ? "mdi-check-circle" : "mdi-lock-open-variant")+" ")])],1):_c('span',[(_vm.mocktest.attempt)?_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v(" mdi-check-circle ")]):_c('v-chip',{class:'px-2 grey darken-1 white--text',attrs:{"x-small":""}},[_vm._v(" free ")])],1),_c('v-btn',{staticClass:"grey--text",attrs:{"x-small":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.calculateDuration(_vm.mocktest))+" ")],1),(false)?_c('v-btn',{staticClass:"grey--text",staticStyle:{"margin-left":"-0.4rem"},attrs:{"x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile',
              params: { id: _vm.mocktest.user.username },
            })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-circle")]),_vm._v(" "+_vm._s(_vm.mocktest.user.display_name)+" ")],1):_vm._e()],1)]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.mocktest.segments.reduce( function (prev, curr) { return prev + curr.__meta__.questions_count; }, 0 ))+" "+_vm._s(_vm.$lang("qstn"))+" ")],1),_c('v-expansion-panel-content',[_c('v-list',{staticStyle:{"margin":"0 -1.5rem"},attrs:{"dense":""}},[_vm._l((_vm.mocktest.segments),function(seg){return _c('v-list-item',{key:seg.id},[_vm._v(" "+_vm._s(_vm.isHindi ? (seg.title_hi ? seg.title_hi : seg.title) : seg.title)+" "),_c('v-spacer'),_vm._v(" "+_vm._s(seg.__meta__.questions_count)+" "+_vm._s(_vm.$lang("qstn"))+" ")],1)}),_c('v-divider'),_c('v-list-item',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-circle")]),_vm._v(" "+_vm._s(_vm.$lang("Created_By"))+" "),_c('v-spacer'),_c('span',{staticStyle:{"margin-left":"-0.4rem"},attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile',
              params: { id: _vm.mocktest.user_id },
            })}}},[_vm._v(" "+_vm._s(_vm.mocktest.user ? _vm.mocktest.user.display_name : _vm.mocktest.user_id)+" ")])],1)],2),_c('v-card-actions',{staticClass:"text-center justify-center",staticStyle:{"margin":"0 -1.5rem"}},[(_vm.mocktest.vcoin > 0 && !_vm.mocktest.owned)?_c('purchase-dialog',{attrs:{"mocktest":_vm.mocktest}}):_vm._e(),(_vm.mocktest.vcoin < 1 || _vm.mocktest.owned)?_c('v-btn',{class:_vm.mocktest.attempt
            ? "orange darken-3"
            : _vm.mocktest.elapsed
            ? 'orange darken-3'
            : 'blue darken-3',attrs:{"text":"","block":"","dark":""},on:{"click":function($event){return _vm.$router.push({
            name: 'exams-mocktest-preview',
            params: { id: _vm.mocktest.id },
          })}}},[_vm._v(" "+_vm._s(_vm.$lang( _vm.mocktest.attempt ? "Result" : _vm.mocktest.elapsed ? "Resume" : "Start" ))+" "),(_vm.mocktest.elapsed)?_c('span',[_vm._v(" ("+_vm._s(_vm.mocktest.elapsed.toString().fancyTime())+") ")]):_vm._e(),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }